import { PayloadAction } from '@reduxjs/toolkit';
import { IColumnDesc } from 'lineupjs';
import cloneDeep from 'lodash/cloneDeep';
import {
  BaseVisConfig,
  isBarConfig,
  isCorrelationConfig,
  isHeatmapConfig,
  isHexbinConfig,
  isSankeyConfig,
  isScatterConfig,
  isViolinConfig,
} from 'visyn_core/vis';

import { EWorkbenchView, IOrdinoAppState, IWorkbenchView } from './interfaces';

function findViewIndex(state: IOrdinoAppState, workbenchIndex: number, viewId: string) {
  return state.workbenches[workbenchIndex]?.views.findIndex((v) => v.uniqueId === viewId) ?? -1;
}

export function patchVisViewColumnIds(
  columnDesc: (IColumnDesc & {
    [key: string]: any;
  })[],
  view: IWorkbenchView,
): IWorkbenchView {
  if (view.type !== EWorkbenchView.Visualization) {
    return view;
  }
  const newView = cloneDeep(view);
  function updateSelectedColumns(config: BaseVisConfig, columnKey: string) {
    if (!config[columnKey]) {
      return; // no config found for the selected column key -> skip
    }
    const selected: { id: string; name: string; desc: string }[] = Array.isArray(config[columnKey]) ? config[columnKey] : [config[columnKey]];
    const visColumns = selected.map((c) => {
      const col = columnDesc.find((d) => d.column === c?.name || d.uniqueId === c.id);
      if (!col) {
        console.error(`Selected column ${c.name} not found in workbench columns`);
        return null;
      }
      return { id: col.uniqueId, name: col.label || col.column, description: col.description };
    });
    const patchedColumns = Array.isArray(config[columnKey]) ? visColumns.filter((v) => v) : visColumns?.[0];
    newView.parameters.visConfig[columnKey] = patchedColumns;
  }

  const config = view.parameters.visConfig;
  const typeConfigurations = [
    { predicate: isScatterConfig, keys: ['numColumnsSelected', 'facets', 'shape', 'color', 'labelColumns'] },
    { predicate: isCorrelationConfig, keys: ['numColumnsSelected'] },
    { predicate: isSankeyConfig, keys: ['catColumnsSelected'] },
    { predicate: isHeatmapConfig, keys: ['catColumnsSelected', 'color', 'aggregateColumn'] },
    { predicate: isBarConfig, keys: ['catColumnSelected', 'facets', 'group', 'numColumnsSelected', 'aggregateColumn'] },
    { predicate: isHexbinConfig, keys: ['numColumnsSelected', 'color'] },
    { predicate: isViolinConfig, keys: ['numColumnsSelected', 'catColumnSelected', 'subCategorySelected', 'facetBy'] },
  ];
  const typeConfiguration = typeConfigurations.find(({ predicate }) => predicate(config));
  if (typeConfiguration) {
    typeConfiguration.keys.forEach((key) => {
      updateSelectedColumns(config, key);
    });
  }

  return newView;
}

export const viewsReducers = {
  addView(state: IOrdinoAppState, action: PayloadAction<{ workbenchIndex: number; view: IWorkbenchView }>) {
    const workbench = state.workbenches[action.payload.workbenchIndex];
    const { view } = action.payload;
    if (view.type === EWorkbenchView.Visualization && !view.parameters.visConfig) {
      view.parameters.visConfig = workbench?.initialVisConfig;
    }
    const updatedView = patchVisViewColumnIds(workbench.columnDescs, action.payload.view);
    state.workbenches[action.payload.workbenchIndex]?.views.push(updatedView);
    if (state.focusWorkbenchIndex === action.payload.workbenchIndex) {
      state.midTransition = false;
    }
  },
  setViewParameters(state: IOrdinoAppState, action: PayloadAction<{ workbenchIndex: number; viewId: string; parameters: any }>) {
    Object.keys(action.payload.parameters).forEach((p) => {
      if (state.workbenches[action.payload.workbenchIndex]) {
        const viewIndex = findViewIndex(state, action.payload.workbenchIndex, action.payload.viewId);
        if (viewIndex >= 0) {
          state.workbenches[action.payload.workbenchIndex].views[viewIndex].parameters[p] = action.payload.parameters[p];
        }
      }
    });
  },
  removeView(state: IOrdinoAppState, action: PayloadAction<{ workbenchIndex: number; viewId: string }>) {
    const viewIndex = findViewIndex(state, action.payload.workbenchIndex, action.payload.viewId);

    if (viewIndex >= 0) {
      state.workbenches[action.payload.workbenchIndex]?.views.splice(viewIndex, 1);
      const rankingFilter = state.workbenches[action.payload.workbenchIndex]?.rankingFilter || [];
      const viewFilters = state.workbenches[action.payload.workbenchIndex]?.views.map((view) => view.filters).flat() || [];
      const filterLength = Array.from(new Set<string>([...viewFilters, ...rankingFilter])).length;
      state.workbenches[action.payload.workbenchIndex].filterLength = filterLength;
    }
  },

  addFilter(state: IOrdinoAppState, action: PayloadAction<{ workbenchIndex: number; viewId: string; filter: string[] }>) {
    const viewIndex = findViewIndex(state, action.payload.workbenchIndex, action.payload.viewId);
    if (viewIndex >= 0) {
      const viewFilters =
        state.workbenches[action.payload.workbenchIndex]?.views
          .filter((v, i) => i !== viewIndex)
          .map((view) => view.filters)
          .flat() || [];
      const rankingFilter = state.workbenches[action.payload.workbenchIndex]?.rankingFilter || [];
      const newFilters = Array.from(new Set<string>([...viewFilters, ...rankingFilter, ...action.payload.filter]));
      state.workbenches[action.payload.workbenchIndex].views[viewIndex].filters = action.payload.filter;
      state.workbenches[action.payload.workbenchIndex].filterLength = newFilters.length;
    }
  },
};
