import { EXTENSION_POINT_VISYN_VIEW, pluginRegistry } from 'visyn_core/plugin';
import { isBarConfig, isViolinConfig } from 'visyn_core/vis';

import { EWorkbenchView, IOrdinoAppState } from '../interfaces';
import type { IInitialState } from './constants';
import { flattenDataProviderDump } from '../../app/dataProvider/utils';
import type { PersistState } from '../persist/types';

export interface IOrdinoMigrationState extends IInitialState, IOrdinoAppState {
  _persist: PersistState;
}

export const ordinoMigrations = {
  /**
   * Migration to update the state to the current version.
   * This migration is a no-op and does not change the state.
   *
   * @param state previous state, e.g., from a session file
   * @returns Returns the update state
   */
  1: (state: IOrdinoMigrationState) => {
    return state;
  },
  /**
   * Migration to update violin plot configurations to the new structure (catColumnSelected, subCategorySelected, facetBy, and overlay)
   * introduced in [visyn_core@11.0.0](https://github.com/datavisyn/visyn_core/releases/tag/v11.0.0).
   *
   * @param state previous state, e.g., from a session file
   * @returns Returns the update state
   */
  2: (state: IOrdinoMigrationState) => {
    // check if there are visualization views in the workbenches. otherwise, skip the migration.
    const hasVisualizationViews = state.workbenches?.some((workbench) => workbench.views.some((view) => view.type === EWorkbenchView.Visualization)) || false;
    if (hasVisualizationViews === false) {
      return state;
    }

    state.workbenches.forEach((workbench, workbenchIndex) => {
      workbench.views.forEach((view, viewIndex) => {
        if (view.type === EWorkbenchView.Visualization && isViolinConfig(view.parameters?.visConfig)) {
          const newVisConfig = { ...view.parameters.visConfig };

          if (newVisConfig.catColumnsSelected) {
            // migrate `catColumnsSelected` (array) to `catColumnSelected` (single object), `subCategorySelected`, and `facetBy`.
            // assign the first 3 selected columns of `catColumnsSelected` into the new properties and ignore any additional columns.
            const [catColumnSelected, subCategorySelected, facetBy] = newVisConfig.catColumnsSelected.slice(0, 3);

            newVisConfig.catColumnSelected = catColumnSelected || null;
            newVisConfig.subCategorySelected = subCategorySelected || null;
            newVisConfig.facetBy = facetBy || null;

            delete newVisConfig.catColumnsSelected;
          }

          if (newVisConfig.violinOverlay) {
            // rename `violinOverlay` to `overlay`
            newVisConfig.overlay = newVisConfig.violinOverlay;
            delete newVisConfig.violinOverlay;
          }

          state.workbenches[workbenchIndex].views[viewIndex].parameters.visConfig = newVisConfig;
        }

        if (view.type === EWorkbenchView.Visualization && isBarConfig(view.parameters?.visConfig)) {
          const newVisConfig = { ...view.parameters.visConfig };

          if (newVisConfig.multiples) {
            // rename `multiples` to `facets`
            // introduced in https://github.com/datavisyn/visyn_core/pull/275
            newVisConfig.facets = newVisConfig.multiples;
            delete newVisConfig.multiples;
          }
        }
      });
    });

    return state;
  },
  /**
   * 1. Migration to move the `dataProviderDump` from the `parameters` of the `ranking` view to the `workbench` level.
   * 2. Migration to set the default view id to the first view id in the `workbench` if it is not set.
   * @param state previous state, e.g., from a session file
   * @returns Returns the update state
   */
  3: (state: IOrdinoMigrationState) => {
    if (!state?.workbenches?.length) {
      return state;
    }
    state.workbenches.forEach((workbench) => {
      const rankingViewWithDump = workbench?.views.find((view) => view.parameters?.dataProviderDump != null);
      if (rankingViewWithDump) {
        const dataProviderDump = { ...rankingViewWithDump.parameters.dataProviderDump };
        (<any>workbench).dataProviderDump = dataProviderDump;
        delete rankingViewWithDump.parameters.dataProviderDump;

        delete rankingViewWithDump.parameters.dataProviderDump;
      }

      const rankingView = workbench?.views.find((view) => view.id.startsWith('reprovisyn_ranking'));
      const viewPlugin = pluginRegistry.getPlugin(EXTENSION_POINT_VISYN_VIEW, rankingView?.id);

      if (viewPlugin?.initialConfig) {
        workbench.rankingConfig = viewPlugin.initialConfig;
      }
      if (!workbench.defaultViewId && workbench?.views?.length > 0) {
        workbench.defaultViewId = workbench.views[0]?.id;
      }
    });
    return state;
  },
  /**
   * Flatten the dataProviderDump of the workbench into it's individual keys.
   * @param state previous state, e.g., from a session file
   * @returns Returns the update state
   */
  4: (state: IOrdinoMigrationState) => {
    if (!state?.workbenches?.length) {
      return state;
    }
    state.workbenches.forEach((workbench) => {
      if ('dataProviderDump' in workbench && workbench.dataProviderDump) {
        const { overrideColumns, aggregations, groupColumns, sortCriteria, groupSortCriteria } = flattenDataProviderDump(workbench.dataProviderDump);
        workbench.overrideColumns = overrideColumns;
        workbench.aggregations = aggregations;
        workbench.groupColumns = groupColumns;
        workbench.sortCriteria = sortCriteria;
        workbench.groupSortCriteria = groupSortCriteria;

        delete workbench.dataProviderDump;
      }
    });
    return state;
  },
};
