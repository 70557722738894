import React from 'react';

import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Text } from '@mantine/core';
import { showNotification, updateNotification } from '@mantine/notifications';
import { v4 as uuidv4 } from 'uuid';
import { i18n } from 'visyn_core/i18n';

import { NamedIdSet, PostApiV1ReprovisynNamedIdSetsCreateApiArg } from './reprovisynApi';
import { NAMED_ID_SET_TAG } from './reprovisynBaseApi';

export const namedIdSetsApiEnhancements = {
  addTagTypes: [NAMED_ID_SET_TAG],
  endpoints: {
    getApiV1ReprovisynNamedIdSets: {
      providesTags: () => [{ type: NAMED_ID_SET_TAG, id: 'LIST' }],
    },
    postApiV1ReprovisynNamedIdSets: {
      providesTags: (result, _error, { namedIdSetFilterArgs: { entityId } }) => {
        return result
          ? [...result.map((r) => ({ type: NAMED_ID_SET_TAG, id: r.id }) as const), { type: NAMED_ID_SET_TAG, id: `LIST_${entityId}` }]
          : [{ type: NAMED_ID_SET_TAG, id: `LIST_${entityId}` }];
      },
    },
    postApiV1ReprovisynNamedIdSetsCreate: {
      onQueryStarted: async (args: PostApiV1ReprovisynNamedIdSetsCreateApiArg, { queryFulfilled }) => {
        const notificationId = uuidv4();
        showNotification({
          loading: true,
          color: 'gray',
          autoClose: false,
          withCloseButton: false,
          id: notificationId,
          title: (
            <Text data-testid="ordino-dataset-create-notification-progress">
              {i18n.t('reprovisyn:notifications.saving', {
                type: i18n.t('reprovisyn:namedIdSet.labelLower'),
                name: args.namedIdSetCreateArgs.name,
                interpolation: { escapeValue: false },
              })}
            </Text>
          ),
          message: '',
        });
        queryFulfilled
          .then(() => {
            return updateNotification({
              id: notificationId,
              loading: false,
              color: 'teal',
              icon: <FontAwesomeIcon icon={faCheck} />,
              title: (
                <Text data-testid="ordino-dataset-create-notification-success">
                  {i18n.t('reprovisyn:notifications.save', {
                    type: i18n.t('reprovisyn:namedIdSet.label'),
                    name: args.namedIdSetCreateArgs.name,
                    interpolation: { escapeValue: false },
                  })}
                </Text>
              ),
              message: '',
              autoClose: 5000,
            });
          })
          .catch(({ error }) => {
            updateNotification({
              id: notificationId,
              loading: false,
              color: 'red',
              title: (
                <Text data-testid="ordino-dataset-create-notification-error">
                  {i18n.t('reprovisyn:notifications.errorSaving', {
                    type: i18n.t('reprovisyn:namedIdSet.label'),
                    name: args.namedIdSetCreateArgs.name,
                    interpolation: { escapeValue: false },
                  })}
                </Text>
              ),
              message: error?.message,
            });
          });
      },
      invalidatesTags: ({ entityId }) => {
        return [
          { type: NAMED_ID_SET_TAG, id: `LIST_${entityId}` },
          { type: NAMED_ID_SET_TAG, id: 'LIST' },
        ];
      },
    },
    deleteApiV1ReprovisynNamedIdSetsBySetId: {
      onQueryStarted: async (args, { queryFulfilled }) => {
        const notificationId = uuidv4();
        showNotification({
          id: notificationId,
          loading: true,
          color: 'gray',
          title: (
            <Text data-testid="ordino-dataset-delete-notification-progress">
              {i18n.t('reprovisyn:notifications.deletingWithTypeWithName', {
                type: i18n.t('reprovisyn:namedIdSet.label'),
                item: i18n.t('reprovisyn:namedIdSet.labelLower'),
                interpolation: { escapeValue: false },
              })}
            </Text>
          ),
          message: '',
          autoClose: false,
          withCloseButton: false,
        });
        queryFulfilled
          .then(({ data: deletedIdSet }) =>
            updateNotification({
              id: notificationId,
              loading: false,
              color: 'teal',
              icon: <FontAwesomeIcon icon={faCheck} />,
              title: (
                <Text data-testid="ordino-dataset-delete-notification-success">
                  {i18n.t('reprovisyn:notifications.deleteSuccessWithName', {
                    type: i18n.t('reprovisyn:namedIdSet.label'),
                    name: deletedIdSet.name,
                    interpolation: { escapeValue: false },
                  })}
                </Text>
              ),
              message: '',
              autoClose: 5000,
            }),
          )
          .catch(({ error }) => {
            updateNotification({
              id: notificationId,
              loading: false,
              color: 'red',
              title: (
                <Text data-testid="ordino-dataset-delete-notification-error">
                  {i18n.t('reprovisyn:notifications.deleteError', {
                    type: i18n.t('reprovisyn:namedIdSet.labelLower'),
                    name: args.setId,
                    interpolation: { escapeValue: false },
                  })}
                </Text>
              ),
              message: error?.message,
            });
          });
      },
      invalidatesTags: ({ entityId }) => {
        return [
          { type: NAMED_ID_SET_TAG, id: `LIST_${entityId}` },
          { type: NAMED_ID_SET_TAG, id: 'LIST' },
        ];
      },
    },
    putApiV1ReprovisynNamedIdSetsBySetId: {
      onQueryStarted: async (args, { queryFulfilled }) => {
        const notificationId = uuidv4();
        showNotification({
          id: notificationId,
          loading: true,
          color: 'gray',
          title: (
            <Text data-testid="ordino-dataset-update-notification-progress">
              {i18n.t('reprovisyn:notifications.saving', {
                type: i18n.t('reprovisyn:namedIdSet.labelLower'),
                name: args?.namedIdSetUpdateArgs.name,
                interpolation: { escapeValue: false },
              })}
            </Text>
          ),
          message: '',
          autoClose: false,
          withCloseButton: false,
        });
        queryFulfilled
          .then(() => {
            updateNotification({
              id: notificationId,
              loading: false,
              color: 'teal',
              icon: <FontAwesomeIcon icon={faCheck} />,
              title: (
                <Text data-testid="ordino-dataset-update-notification-success">
                  {i18n.t('reprovisyn:notifications.save', {
                    type: i18n.t('reprovisyn:namedIdSet.label'),
                    name: args?.namedIdSetUpdateArgs.name,
                    interpolation: { escapeValue: false },
                  })}
                </Text>
              ),
              message: '',
              autoClose: 5000,
            });
          })
          .catch(({ error }) => {
            updateNotification({
              id: notificationId,
              loading: false,
              color: 'red',
              title: (
                <Text data-testid="ordino-dataset-update-notification-error">
                  {i18n.t('reprovisyn:notifications.errorSaving', {
                    type: i18n.t('reprovisyn:namedIdSet.labelLower'),
                    name: args?.namedIdSetUpdateArgs.name,
                    interpolation: { escapeValue: false },
                  })}
                </Text>
              ),
              message: error?.message,
            });
          });
      },
      invalidatesTags: (namedIdSet: NamedIdSet) => {
        return [
          { type: NAMED_ID_SET_TAG, id: namedIdSet.id },
          { type: NAMED_ID_SET_TAG, id: 'LIST' },
        ];
      },
    },
  },
};
